import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-signos-dialog',
    templateUrl: './signos-dialog.component.html',
    styleUrls: ['./signos-dialog.component.scss'],
})
export class SignosDialogComponent implements OnInit {
    signosVitales: any;
    paciente: string;
    constructor(
        public matDialogRef: MatDialogRef<SignosDialogComponent>,
        private matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {}

    ngOnInit() {
        this.signosVitales = this._data.signos_vitales;
        this.paciente = this._data.paciente;
    }
}
