import { Component, OnInit, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatExpansionPanel } from '@angular/material';
import { CitasService } from '../citas/citas.service';
import { FuseConfigService } from '@fuse/services/config.service'
import { NetworkService } from 'app/services/network-service.service';

@Component({
    selector: 'app-menu_izquierdo',
    templateUrl: './menu_izquierdo.component.html',
    styleUrls: ['./menu_izquierdo.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MenuIzquierdoComponent implements OnInit, OnDestroy {
    // Variables privadas
    private panel: number;
    private _unsubscribeAll: Subject<any>;

    consulta_id: any;
    paciente_id: any;
    cita_id: any;
    img_src: any;
    selected: any;
    hideOption: boolean;
    view: any;
    hideToggle: boolean;
    subscription: Subscription;
    isNurse: boolean
    showPanel: boolean = true

    results = {
        alergias: false,
        antecedentes: false,
        signos_vitales: false,
        historial_citas: false,
    };

    private ingresado = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private citaService: CitasService,
        private fuseConfig: FuseConfigService,
        private networkService: NetworkService
    ) {
        // this._fuseTranslationLoaderService.loadTranslations(english, spanish);
        this.panel = 0;
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {
        // Parametros de url
        this.fuseConfig.getConfig().subscribe(data => this.isNurse = data.isNurse)
        this.consulta_id = this.route.snapshot.paramMap.get('consulta_id');
        this.view = this.route.snapshot.paramMap.get('view_id');
        this.paciente_id = this.route.snapshot.paramMap.get('paciente_id');
        this.cita_id = this.route.snapshot.paramMap.get('cita_id');
        this.networkService.getShowSidebar().subscribe(data => this.showPanel = data)

        this.view !== 'citas'
            ? (this.hideToggle = true)
            : (this.hideToggle = false);

        // Subcripcion a cambio de url y actualizacion de parametros
        this.subscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.consulta_id = this.route.snapshot.paramMap.get(
                    'consulta_id'
                );
                this.paciente_id = this.route.snapshot.paramMap.get(
                    'paciente_id'
                );
                this.cita_id = this.route.snapshot.paramMap.get('cita_id');
            }
        });
    }

    setPanel(index: number): void {
        this.panel = index;
    }

    setResults(result, bool): void {
        this.results[result] = bool;
    }

    cambiarRuta(parametro, expansionPanel: MatExpansionPanel): void {
        if (expansionPanel.expanded) {
            expansionPanel.close();
        } else {
            expansionPanel.open();
        }
        const paciente_id = this.route.snapshot.paramMap.get('paciente_id');
        const cita_id = this.route.snapshot.paramMap.get('cita_id');
        const consulta_id = this.route.snapshot.paramMap.get('consulta_id');
        const om_id = this.route.snapshot.paramMap.get('om_id');

        // this.router.navigate(['medic/history/' + paciente_id + '/' + cita_id + '/agregar_alergia']);
        this.router.navigate([
            '/medic/history/' +
                paciente_id +
                '/' +
                cita_id +
                '/' +
                parametro +
                '/' +
                consulta_id +
                '/' +
                om_id,
        ]);
    }

    historicoCita($event): void {
        this.consulta_id = $event.consulta_id;
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.subscription.unsubscribe();
    }
}
