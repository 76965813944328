import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'account',
        title: 'Cuenta',
        translate: 'NAV.ACCOUNT',
        type: 'group',
        children: [
            // {
            //     id       : 'home',
            //     title    : 'Principal',
            //     translate: 'NAV.HOME.TITLE',
            //     type     : 'item',
            //     icon     : 'home',
            //     url      : '/home'
            // }
        ],
    },
];
