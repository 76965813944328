import { Injectable } from "@angular/core";
import { NetworkService } from "app/services/network-service.service";
import { Subject } from "rxjs/internal/Subject";
import { Observable } from "rxjs";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class SearchBarService {

    public data;

    constructor() {}
}