import { Injectable } from "@angular/core";
import { NetworkService } from "app/services/network-service.service";
import { Subject } from "rxjs/internal/Subject";
import { Observable } from "rxjs";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class BusquedaService {

    constructor(private networkService: NetworkService) {}

    getBusqueda(search): Observable<any> {
        return this.networkService.get('busqueda/' + search);
    }
}