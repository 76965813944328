import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
    MatButtonModule,
    MatIconModule,
    MAT_DATE_LOCALE,
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SessionInterceptor } from './guards/interceptors/session.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { PipesCustomModule } from './pipes/pipes.module';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

const appRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'home',
                loadChildren: './main/home/home.module#HomeModule',
            },
            {
                path: 'medic',
                loadChildren: './main/medic/medic.module#MedicModule',
            },
            // {
            //     path: 'presupuesto',
            //     loadChildren:
            //         './main/presupuesto/presupuesto.module#PresupuestoModule',
            // },
            {
                path: 'honorarios',
                loadChildren:
                    './main/honorarios/honorarios.module#HonorariosModule',
            },
            {
                path: 'agenda',
                loadChildren: './main/agenda/agenda.module#AgendaModule',
            },
            {
                path: 'busqueda',
                loadChildren: './main/busqueda/busqueda.module#BusquedaModule',
            },
        ],
        canActivate: [AuthGuard],
    },
    {
        path: 'auth',
        loadChildren: './main/auth/auth.module#AuthModule',
    },
    {
        path: 'pacientes',
        loadChildren: './main/patient/patient.module#PatientModule',
        canActivate: [AuthGuard],
    },
    // {
    //     path: 'portal',
    //     loadChildren: './main/portal/portal.module#PortalModule',
    //     canActivate: [AuthGuard],
    // },
];
registerLocaleData(es);
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        // Pipes Custom Module
        PipesCustomModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionInterceptor,
            multi: true,
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'en-GB',
        },
        { provide: LOCALE_ID, useValue: 'es-*' },
    ],
})
export class AppModule {}
