export const locale = {
    lang: 'es',
    data: {
        NAV: {
            ACCOUNT: 'Cuenta',
            PROFILE: {
                TITLE: 'Perfil',
                BADGE: '25',
                PASSWORD: 'Nueva Contraseña',
                CONFIRM_PASSWORD: 'Confirmar Nueva Contraseña',
            },
            HOME: {
                TITLE: 'Principal',
                BADGE: '25',
            },
            MEDIC_MENU: 'Médico',
            ADMIN_MENU: 'Administrador',
            PATIENTS_OF_THE_DAY: {
                TITLE: 'Pacientes del Día',
            },
            PRESUPUESTO: {
                TITLE: 'Presupuesto',
            },
            HONORARIOS: {
                TITLE: 'Honorarios',
            },
            AGENDA: {
                TITLE: 'Agenda Médica',
            },
            HOSPITALIZATION: {
                TITLE: 'Hospitalización',
            },
            DASHBOARD: {
                TITLE: 'Tablero',
            },
            LOGOUT: {
                TITLE: 'Desconectarse',
                MESSAGE: '¿Estás seguro que quiere desconectarse?',
                YES: 'Sí',
                NO: 'No',
            },
            PATIENTS: {
                TITLE: 'Pacientes',
            },
            CALENDAR: {
                TITLE: 'Calendario',
            },
            PORTAL: {
                HOME: 'Principal',
                PATIENT: 'Pacientes',
                ATTEND: 'Atender pacientes',
                REPORT: 'Informes médicos',
                CHART: 'Reportes',
            },
        },
    },
};
