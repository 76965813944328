import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../main/auth/auth.service';
import { fuseConfig } from '../fuse-config';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { rolesNavigation } from '../fuse-config/roles';
import 'rxjs/add/operator/map';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private http: HttpClient,
        public authService: AuthService,
        public fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService
    ) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        //   console.log(this.authService.isAuthenticated);
        if (
            (this.authService.isAuthenticated === false &&
                localStorage.getItem('authToken') !== null) ||
            (this.authService.isAuthenticated === false &&
                sessionStorage.getItem('authToken') !== null)
        ) {
            const token =
                localStorage.getItem('authToken') ||
                sessionStorage.getItem('authToken') ||
                '';
            const headers = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: token,
                }),
                withCredentials: true,
            };
            return this.http.get(fuseConfig.URL + 'user/', headers).map(
                (res: any) => {
                    this.authService.isAuthenticated = true;
                    this.fuseConfigService.config = {
                        user: {
                            id: res.userId,
                            firstname: res.firstname,
                            lastname: res.lastname,
                            email: res.email,
                            image: res.image
                                ? res.image
                                : 'assets/images/avatars/profile.jpg',
                            especialidades: res.especialidades,
                            ambulatorio: res.ambulatorio,
                        },
                    };
                    // Find each role to enable navigation
                    this._fuseNavigationService.setNavigationRoles(res.role);

                    // Set global roles booleans
                    this.fuseConfigService.config = {
                        isMedic: false,
                        isAdmin: false,
                        isNurse: false,
                    };
                    const medico = res.role.findIndex((role) => {
                        return role.name === 'medico';
                    });

                    if (medico >= 0) {
                        this.fuseConfigService.config = {
                            isMedic: true,
                        };
                    }

                    const admin = res.role.findIndex((role) => {
                        return role.name === 'admin';
                    });

                    if (admin >= 0) {
                        this.fuseConfigService.config = {
                            isAdmin: true,
                        };
                    }

                    const nurse = res.role.findIndex((role) => {
                        return role.name === 'enfermeria';
                    });

                    if (nurse >= 0) {
                        this.fuseConfigService.config = {
                            isNurse: true,
                        };
                    }
                    return true;
                },
                (err) => {
                    // console.log(err);
                    this.router.navigate(['/auth/login']);
                    return false;
                }
            );
        } else if (this.authService.isAuthenticated === true) {
            return true;
        } else {
            // console.log('aca');
            this.router.navigate(['/auth/login']);
            return false;
        }
    }
}
