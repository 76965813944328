import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatDividerModule,
    MatTooltipModule,
} from '@angular/material';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChangePasswordModule } from 'app/modules/shared/components/change-password/change-password.module';

@NgModule({
    declarations: [ToolbarComponent],
    imports: [
        RouterModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatDividerModule,
        MatTooltipModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,

        ChangePasswordModule,
    ],
    exports: [ToolbarComponent],
})
export class ToolbarModule {}
