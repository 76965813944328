import {
    Component,
    OnInit,
    ChangeDetectorRef,
    ViewChild,
    EventEmitter,
    Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryService } from '../../history.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ITreeOptions } from 'angular-tree-component';
import { CitasService } from '../../citas/citas.service';
import { MatDialog } from '@angular/material';
import { CitasModalComponent } from 'app/main/portal/pacientes/citas-modal/citas-modal.component';

@Component({
    selector: 'app-historial-citas',
    templateUrl: './historial_citas.component.html',
    styleUrls: ['./historial_citas.component.scss'],
})
export class HistorialCitasComponent implements OnInit {
    // Variables publicas
    data: any;
    portal: boolean;
    node = [];
    filter = '';
    options: ITreeOptions = {
        // displayField: 'nodeName',
        isExpandedField: 'expanded',
        // idField: 'uuid',
        // hasChildrenField: 'nodes',
        // nodeHeight: 23,
        // allowDragoverStyling: true,
        // levelPadding: 10,
        // useVirtualScroll: true,
        animateExpand: true,
        // scrollOnActivate: true,
        animateSpeed: 15,
        // animateAcceleration: 1.2,
        // scrollContainer: document.documentElement, // HTML
    };
    view: any;
    paciente_id: any;

    @ViewChild('tree') tree;
    @Output() historicoCita = new EventEmitter();

    constructor(
        private historyService: HistoryService,
        private route: ActivatedRoute,
        private router: Router,
        private citasServ: CitasService,
        private cdf: ChangeDetectorRef,
        private dialog: MatDialog
    ) {
        this.data = {
            historial: [],
        };
    }

    ngOnInit(): void {
        this.router.url.includes('portal')
            ? (this.portal = true)
            : (this.portal = false);

        this.paciente_id = this.route.snapshot.paramMap.get('paciente_id');
        this.view = this.route.snapshot.paramMap.get('view_id');

        this.obtenerHistoricoCitas(this.paciente_id);
    }

    obtenerHistoricoCitas(paciente_id): void {
        this.data.historial = [];
        this.historyService.getHistoricoCitas(paciente_id).subscribe(
            (res) => {
                this.data.historial = [...res];
                this.prepareTree(this.data.historial);
            },
            (err) => {
                console.error(err);
            }
        );
    }

    // Evento que ocurre al hacer click en uno de los nodos
    onEvent($event): void {
        const nodeParams = $event.node.id.split('/');
        const params = window.location.hash.split('/');

        if (
            $event.node.id.length > 10 &&
            this.view !== 'citas' &&
            !this.portal
        ) {
            window.open(
                `${params[0]}/${params[1]}/${params[2]}/${params[3]}/${nodeParams[0]}/citas/${nodeParams[1]}/${nodeParams[0]}`,
                '_blank'
            );
        } else if ($event.node.id.length > 10 && !this.portal) {
            this.router.navigate([
                `${params[1]}/${params[2]}/${params[3]}/${nodeParams[0]}/citas/${nodeParams[1]}/${nodeParams[0]}`,
            ]);
            this.historicoCita.emit({
                cita_id: nodeParams[0],
                consulta_id: nodeParams[1],
            });
            this.citasServ.changeId(nodeParams[0]);
        } else if ($event.node.id.length > 10) {
            this.dialog.open(CitasModalComponent, {
                width: '70%',
                height: '100%',
                panelClass: 'custom-dialog',
                data: {
                    paciente_id: this.paciente_id,
                    cita_id: nodeParams[0],
                    consulta_id: nodeParams[1],
                },
            });
        }
    }

    filterNodes(txt = this.filter): void {
        this.tree.treeModel.filterNodes((node) => {
            return node.data.name.toLowerCase().includes(txt.toLowerCase());
        });

        const hidden = Object.keys(this.tree.treeModel.hiddenNodeIds);

        hidden.forEach((ele) => {
            const node = this.tree.treeModel.getNodeById(ele);

            switch (node.level) {
                case 1:
                    break;

                case 2:
                    if (
                        node.parent.data.name
                            .toLowerCase()
                            .includes(txt.toLowerCase())
                    ) {
                        node.show();
                    }
                    break;

                case 3:
                    if (
                        node.parent.data.name
                            .toLowerCase()
                            .includes(txt.toLowerCase())
                    ) {
                        node.show();
                    } else {
                        const otherNode = this.tree.treeModel.getNodeById(
                            node.parent.data.id
                        );

                        if (
                            otherNode.parent.data.name
                                .toLowerCase()
                                .includes(txt.toLowerCase())
                        ) {
                            node.show();
                        }
                    }
                    break;
            }
        });
    }

    // Prepara el primer nodo
    prepareTree(res: any): void {
        res.forEach((ele, index) => {
            const date = new Date(ele.fecha);
            if (index === 0) {
                this.node.push({
                    id: date.getFullYear().toString(),
                    name: date.getFullYear().toString(),
                    children: [
                        {
                            id: date.getMonth().toString(),
                            name: this.getMonthName(date.getMonth()),
                            children: [
                                {
                                    id: ele.id + '/' + ele.tipo_consulta.id,
                                    name: `${date.getDate()} - ${
                                        ele.tipo_consulta.nombre_corto
                                    } - ${ele.especialidad.nombre} ${
                                        ele.medico.nombre
                                    }`,
                                },
                            ],
                        },
                    ],
                });
            } else {
                this.levelOne(date, ele);
            }
        });
        this.tree.treeModel.update();
    }

    // Añadir un nuevo nodo de año
    levelOne(date: Date, resEle: any): void {
        this.node.forEach((ele, index) => {
            if (ele.id !== date.getFullYear().toString()) {
                this.node.push({
                    id: date.getFullYear().toString(),
                    name: date.getFullYear().toString(),
                    children: [],
                });
            }

            this.levelTwo(date, ele, index, resEle);
        });
    }

    // Añadir un nuevo nodo de mes
    levelTwo(date: Date, element: any, index: any, resEle: any): void {
        element.children.forEach((ele, i) => {
            if (ele.id !== date.getMonth().toString()) {
                this.node[index].children.push({
                    id: date.getFullYear().toString(),
                    name: date.getFullYear().toString(),
                    children: [],
                });
            }
            this.levelThree(date, ele, resEle);
        });
    }

    // Añadir un nuevo nodo de info de cita
    levelThree(date: Date, ele: any, element): void {
        ele.children.push({
            id: element.id + '/' + element.tipo_consulta.id,
            name: `${date.getDate()} - ${
                element.tipo_consulta.nombre_corto
            } - ${element.especialidad.nombre} ${element.medico.nombre}`,
        });
    }

    // Retorna string con el nombre de mes
    getMonthName(number): string {
        switch (number) {
            case 1:
                return 'Enero';
            case 2:
                return 'Febrero';
            case 3:
                return 'Marzo';
            case 4:
                return 'Abril';
            case 5:
                return 'Mayo';
            case 6:
                return 'Junio';
            case 7:
                return 'Julio';
            case 8:
                return 'Agosto';
            case 9:
                return 'Septiembre';
            case 10:
                return 'Octubre';
            case 11:
                return 'Noviembre';
            case 12:
                return 'Diciembre';
        }
    }

    onUpdateData(tree): void {
        if (window.location.href.includes('portal')) {
            tree.treeModel.expandAll();
        }
    }
}
