import { Injectable } from '@angular/core';
import { NetworkService } from 'app/services/network-service.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PortalService {
    constructor(private networkService: NetworkService) {}

    // EP Home

    /**
     * Obtiene lista de citas de Consulta externa aisgnadas a un medico.
     * @param desde Date string desde fecha
     * @param hasta Date string hasta fecha
     */
    getAgendaCE(desde: string, hasta: string): Observable<any> {
        return this.networkService.get(`medico/agendace/${desde}/${hasta}`);
    }

    getEstadisticasCE(desde: string, hasta: string): Observable<any> {
        return this.networkService.get(
            `medico/estadisticasce/${desde}/${hasta}`
        );
    }

    // EP pacientes

    /**
     * Retorna una lista de los apcientes registardos en sistema.
     */
    getPacientes(): Observable<any> {
        return this.networkService.get('pacientes');
    }

    /**
     * Retorna una lista de los pacientes con filtro.
     */
    getPacientesFiltrado(texto: string): Observable<any> {
        return this.networkService.get(
            `pacientes?filter=texto:like:${texto}&limit=50000`
        );
    }

    getPacienteById(id): Observable<any> {
        return this.networkService.get('pacientes/' + id);
    }

    searchPacientes(str): Observable<any> {
        return this.networkService.get('pacientes/busqueda/' + str);
    }

    getPacienteFoto(data) {
        return this.networkService.get('foto/paciente/' + data);
    }

    getCitas(desde, hasta) {
        return this.networkService.get(
            'medico/agendace' + '/' + desde + '/' + hasta
        );
    }

    postCita(data) {
        return this.networkService.post('citas/ce', data);
    }

    putCita(data) {
        return this.networkService.put('citas/ce', data);
    }

    deleteCita(cita) {
        return this.networkService.delete('citas/ce/' + cita);
    }

    /**
     * Retorna lista de paises
     */
    getPaises(): Observable<any> {
        return this.networkService.get('pais');
    }

    /**
     * Retorna lista de estados de acuerdo al pais seleccionado
     * @param id_pais id del pais seleccionado
     */
    getEstados(id_pais): Observable<any> {
        return this.networkService.get(`estado/${id_pais}`);
    }

    /**
     * Retorna lista de ciudades de acuerdo al pais seleccionado
     * @param id_estado id del estado seleccionado
     */
    getCiudades(id_estado): Observable<any> {
        return this.networkService.get(`ciudad/${id_estado}`);
    }

    /**
     * Registrar pacientes
     */
    postPacientes(body: any): Observable<any> {
        return this.networkService.post('pacientes', body);
    }

    /**
     * Actualizar pacientes
     */
    updatePacientes(body: any): Observable<any> {
        return this.networkService.put('pacientes', body);
    }
}
