export const locale = {
    lang: 'es',
    data: {
        MAIN: {
            NO_RESULTS: 'No se encontraron resultados',
            LEFT_MENU: 'Menú izquierdo',
            YEARS: 'años',
            NONE: 'No se encontraron resultados',
            DAYS: 'días',
            YES: 'Sí',
        },
        HISTORY: {
            HELLO: 'Hola, Pacientes!',
            TITLE: 'Hospitalización',
            REGISTERED: 'Registrado',
            MEDICAL_ORDER: 'Órden Médica',
            ACTIONS: 'Acciones',
            ACTION: 'Acción',
        },
        PATIENTS_OF_THE_DAY: {
            TITLE: 'Pacientes del Día',
        },
        TIMELINE: {
            TITLE: 'Línea de Tiempo',
            NO_DATA: 'No hay datos',
            IMAGES: 'Imágenes',
            LABORATORY: 'Laboratorio',
            TREATMENT: 'Tratamiento',
            HOSPITALIZATION: 'Hospitalización',
            DIET: 'Dieta',
            MIXES: 'Mezclas',
            SURGERY: 'Cirugía',
            INTERCONSULTATION: 'Interconsulta',
            EXTERNAL_CONSULTATION: 'Consulta Externa',
            POSITION: 'Posición',
            OTHERS: 'Otros',
            ACTIVITIES: 'Actividades',
            ALLERGIES: 'Alergias',
            EGRESS: 'Alta Médica',
            RECORD: 'Antecedentes',
            BALANCE: 'Balance Hídrico',
            APPOINTMENTS: 'Citas',
            NURSING_EVOLUTION: 'Ev. Enfermería',
            NURSING_EVOLUTION_TOOLTIP: 'Evolución Enfermería',
            MEDICAL_EVOLUTION: 'Ev. Médica',
            MEDICAL_EVOLUTION_TOOLTIP: 'Evolución Médica',
            HISTORY: 'Historia Ing.',
            REPORT: 'Informe Médico',
            OM: 'Órdenes Médicas',
            VITAL_SIGN: 'Signo Vitales',
            SURGICAL_REPORT: 'Informe Qui.',
            SURGICAL_REPORTS: 'Informes Qui.',
            SURGICAL_REPORTS_TOOLTIP: 'Informes Quirúrgicos',
            DATA: 'Datos',
            CLOSE: 'Cerrar',
            LOADING: 'Cargando . . .',
            EMERGENCY: 'Emergencia',
            ALDRETE: 'Aldrete',
            NONE: 'No se encontraron resultados',
            ANNEX: 'Agregar anexo',
        },
        MENU_IZQUIERDO: {
            VIEW_LIST: 'Ver lista',
            VIEW_CHART: 'Ver gráfica',
            ACTIONS: 'Acciones',
            ALLERGIES: 'Alergias',
            RECORD: 'Antecedentes',
            VITAL_SIGNS: 'Signos Vitales',
            VITAL_SIGNS_ADDED: 'Signo vital guardado',
            ADD_COMMENT: 'Para "Otros" el campo comentarios es requerido',
            APPOINTMENT_HISTORY: 'Citas Recientes',
            BLOOD_TYPE: 'Grupo sanguíneo',
            NOTES: 'Notas',
            PATHOLOGICAL: 'Patológicos',
            NO_PATHOLOGICAL: 'No Patológicos',
            ADD_ALLERGY: 'Agregar Alergia',
            ADD_RECORD: 'Agregar Antecedente',
            ADD_VITAL_SIGNS: 'Agregar Signos Vitales',
            VIEW_ALLERGY: 'Ver Alergias',
            VIEW_RECORD: 'Ver Antecedentes',
            VIEW_VITAL_SIGNS: 'Ver Signos Vitales',
            NOT_ALLERGIES: 'No presenta alergias',
            ACTIVE_PRINCIPLE: 'Seleccione principio activo (Opcional)',
            ACTIVE_PRINCIPLE_D: 'Principio Activo',
            DATE: 'Seleccione fecha (Opcional)',
            DATE_N: 'Seleccione fecha',
            COMMENTS: 'Comentarios (Opcional)',
            NOT_RECORD: 'No presenta antecedentes',
            TYPE_RECORD: 'Tipo de Antecedente',
            INVALID_OPTION: 'Opción Invalida',
            PATHOLOGICAL_Q: '¿Patológico?',
            DESCRIPTION: 'Descripción',
            REQUIRED_FIELD: 'Campo requerido!',
            TYPE: 'Tipo',
            MEDIC: 'Médico',
            OBSERVATION: 'Observación',
            DATE_D: 'Fecha',
            DATE_O: 'Fecha (Opcional)',
            NO_VITAL_SIGNS: 'No presenta signos vitales',
            OUT_RANGE: 'Fuera de rango',
            ALLOWED_RANGE: 'Valores permitidos',
            GRAPH: 'Gráfica',
            VALUE: 'Valor',
            DATE_H: 'Fecha / Hora',
            HOUR: 'Hora',
            MALE: 'Masculino',
            FEMALE: 'Femenino',
            RESPONSIBLE: 'Responsable de pago',
            TREATING: 'Medico tratante',
            AREA: 'Área',
            FLOOR: 'Piso',
            ROOM: 'Cuarto',
            ROOM_INFO: 'Seleccione un área y piso',
            BED: 'Cama',
            BED_INFO: 'Seleccione un cuarto',
            LOCATION: 'Ubicación',
            ASSIGN: 'Sin asignar',
            CONSULT: 'Consulta',
            ESPECIALTY: 'Especialidad',
            MEDICAL_TRANSFER: 'Traslado médico',
            LOCATION_TRANSFER: 'Traslado de ubicación',
            BLUE: 'Azúl: Confirmado',
            RED: 'Rojo: No confirmado',
            CONFIRMATION_EMAIL: '¿Desea enviar correo de confirmación?',
            PASSWORD: 'Contraseña',
            PASSWORD_CONFIRM: 'Confirmar contraseña',
            CONFIRMATION_PWD_MSG:
                'Ingrese su contraseña para ejecutar \n la acción',
            RESPONSIBLE_SIGNS: 'Responsable',
            CHANGE_TO_APPOINTMENT: 'Historia Clínica',
            CHANGE_TO_NOTE: 'Nota Evolutiva',
        },
        EVOLUCIONES: {
            MEDICAL_EVOLUTION: 'Evoluciones Medicas',
            ADD_MEDICAL: 'Registrar evolución médica',
            NURSE_EVOLUTION: 'Evoluciones Enfermeria',
            ADD_NURSE: 'Registrar evolución de enfermeria',
        },
        ALTA: {
            EGRESS: 'Alta medica',
            DATE: 'Fecha',
            TYPE: 'Tipo',
            REPORT: 'Informe',
            MEDIC: 'Médico',
            CANCEL: 'Cancelar',
            SELECT_EGRESS: 'Seleccione tipo de egreso',
            EMPTY_TEXT: 'Introduzca descripción',
        },
        BUTTONS: {
            ADD: 'Agregar',
            CLEAR: 'Borrar',
            DELETE: 'Eliminar',
            CANCEL: 'Cancelar',
            SAVE: 'Guardar',
            UPDATE: 'Actualizar',
            NEXT: 'Siguiente',
            BACK: 'Atrás',
            BACK_CALENDAR: 'Regresar a calendario',
            BACK_HISTORY: 'Regresar a historia médica',
            FIRST: 'Primero',
            LAST: 'Último',
            LOAD: 'Cargar más',
            FINALIZE: 'Finalizar reporte',
        },
        OM: {
            OMITTED: 'Omitidos',
            SEARCH: 'Búsqueda',
            SEARCH_P: 'Búsqueda por parametros',
            OMIT: 'Omitir',
            EDIT: 'Editar',
            OMITTED_BY: 'Omitido por: ',
            AREA: 'Área',
            AREA_S: 'Seleccione área',
            INDICATIONS: 'Indicaciones',
            INDICATIONS_O: 'Indicaciones (Opcional)',
            DIET: 'Dieta',
            DIET_S: 'Seleccione dieta',
            TREATMENT: 'Tratamiento',
            TREATMENT_S: 'Búsqueda por tratamiento',
            TA_TEXT:
                'El paciente es alergico al componente activo del medicamento. ¿Desea continuar?',
            TA_CANCELBTN: 'Cancelar',
            TA_ACCEPTBTN: 'Aceptar',
            VALUE_1: 'Valor mínimo es 1!',
            VIA: 'Vía',
            DOSE: 'Dosis',
            MEASURE: 'Medida',
            INTERVAL: 'Intervalo',
            ALL: 'TODOS',
            EXAM: 'Exámen',
            EXAMS: 'Exámenes',
            EXAM_S: 'Búsqueda por exámenes',
            EXAM_P: 'Búsqueda por perfiles',
            LIST: 'Listado',
            PROFILES: 'Perfiles',
            SURGERY: 'Cirugía',
            SPECIALTY: 'Especialidad',
            SPECIALTY_S: 'Seleccione especialidad',
            INTERVENTION: 'Intervención',
            INTERVENTION_S: 'Seleccione intervención',
            MEDIC: 'Médico',
            MEDIC_S: 'Seleccione médico (Opcional)',
            REASON: 'Motivo',
            POSITION: 'Posición',
            POSITION_S: 'Seleccione posición',
            OTHERS: 'Otros',
            THERAPY: '¿Es terapia respiratoria?',
        },
        ACTIVIDADES: {
            NURSE_ACTIVITIES: 'Actividades enfermería',
            ADD_ACTIVITY: 'Añadir actividad',
            PENDING_ACTIVITIES: 'Actividades pendientes',
            PERFORMED_ACTIVITIES: 'Actividades realizadas',
            ACTIVITIES_LIST: 'Lista de actividades',
            EDIT_ACTIVITY: 'Editar actividad',
            DELETE_ACTIVITY: 'Borrar actividad',
        },
        MEDICAL_REPORTS: {
            NO_TEXT: 'El contenido del informe está vacío',
            NO_DATA: 'Este paciente no tiene reportes médicos',
            NO_TEMPLATE:
                'El informe debe ser hecho a partir de una de las plantillas listadas',
            MEDICAL_REPORTS: 'Informes Médicos',
            TYPES_REPORTS: 'Seleccione plantilla',
            PREVIEW: 'Vista Previa',
            DATE: 'Fecha',
            TYPE: 'Tipo',
            DETAILS: 'Detalles',
            MEDIC: 'Médico',
            ACTION: 'Acción',
            PRINT: 'Imprimir',
        },
        TREATMENT: {
            DATE: 'Fecha',
            HOUR: 'Hora',
            TREATMENT: 'Tratamiento',
            DOSE: 'Dosis',
            VIA: 'Vía',
            NURSE: 'Enfermera',
            STATUS: 'Estatus',
        },
        NOTA_OPERATORIA: {
            OPERATING_NOTE: 'Nota Operatoria',
            DETAIL: 'Detalle',
            NO_DETAIL: 'Seleccione una note operatoria para ver su detalle',
            OPERATING_NOTE2: 'Notas Operatorias',
            DATE: 'Fecha: ',
            DATE2: 'Fecha',
            TO: ' a ',
            INTERVENTION: 'Datos de Intervención',
            INTERVENTION2: 'Intervención',
            PARTICIPANTS: 'Participantes',
            SURGEON: 'Cirujano Principal: ',
            DIAGNOSTICS: 'Diagnósticos',
            'PRE-OPERATIVE': 'Pre-Operatorio: ',
            'POST-OPERATIVE': 'Post-Operatorio: ',
            PROCEDURE: 'Descripción del procedimiento',
            TIME: 'Tiempo de intervención: ',
            ANESTHESIA: 'Tipo de anestesia: ',
            PRIORITY: 'Prioridad: ',
            TRANSIT: 'Tránsito: ',
            FINDINGS: 'Hallazgos: ',
            EVOLUTION:
                'Evolución y condición del paciente al término de la cirugía: ',
            SPECIALTY: 'Especialidad',
            MEDIC: 'Médico',
            ACTION: 'Acción',
            NO_DATA: 'Este paciente no tiene notas operatorias',
        },
        BALANCE: {
            BALANCE_HIDRICO: 'Balance Hidrico',
            INGESTA: 'Ingesta',
            EXCRETA: 'Excreta',
            TOTAL_INGESTA: 'Total Ingesta:',
            TOTAL_EXCRETA: 'Total Excreta:',
            BALANCE: 'Balance:',
            REGISTRADO: 'Registrado por',
            CANTIDAD: 'Cantidad',
            DETALLE: 'Detalle',
            TIPO: 'Tipo',
            FECHA: 'Fecha/Hora',
            TODAS: 'Todas',
            DESDE: 'Desde:',
            HASTA: 'Hasta:',
            HOY: 'Hoy',
            '7DIAS': 'Ultimos 7 dias',
            MES: 'Mes',
            PRINCIPAL: 'Principal',
            AGREGAR: 'Agregar',
            CANCELAR: 'Cancelar',
        },
        IMAGENES: {
            IMAGENES: 'Imagenes',
            RESULTADOS: 'Resultados',
            PENDIENTES: 'Pendientes',
            FECHA: 'Fecha',
            EXAMEN: 'Examen',
            SOLICITADO: 'Solicitado por',
            INFORMADO: 'Informado por',
            INFORME: 'Informe',
            IMAGEN: 'Imagen',
            ACCION: 'Accion',
            EXTERNOS: 'Añadir resultados externos',
            ARCHIVO: 'Archivo',
            SELECCIONE: 'Seleccione el archivo',
            BUSCAR: 'Buscar',
            ADJUNTAR: 'Adjuntar',
            MOSTRAR: 'Mostrar Todos',
            SEE_ALL_APPOINTMENTS: 'Ver todas las citas',
            SEE_ALL: 'Ver todos',
            INCLUDE_RESULTS: 'Incluir resultados',
            SEE_IMAGE: 'Ver imagen',
            MODAL_TITLE: 'Imagen',
            MODAL_BTN: 'Cerrar',
            HALLAZGO: 'Hallazgo',
        },
        QUILL: {
            FORMAT_OPTIONS: 'Opciones de formato',
            LINK: 'Insertar enlace',
            IMAGE: 'Insertar imágen',
            FONT: 'Fuente',
            SIZE: 'Tamaño',
            SMALL: 'Pequeño',
            LARGE: 'Largo',
            BIG: 'Enorme',
            BOLD: 'Negrita',
            ITALIC: 'Cursiva',
            UNDERLINE: 'Subrayado',
            TEXT_COLOR: 'Color del texto',
            BACKGROUND_COLOR: 'Color del fondo',
            ALIGNMENT: 'Alineación',
            LIST_ORDERED_NUMBER: 'Lista ordenada de números',
            LIST_ORDERED_BULLET: 'Lista ordenada de viñetas',
            MORE_INDENT: 'Más sangría',
            DECREASE_INDENT: 'Reducir sangría',
            QUOTE: 'Cita',
            STRIKETHROUGH: 'Tachado',
            CLEAR: 'Eliminar formato',
            UNDO: 'Deshacer',
            REDO: 'Rehacer',
        },
        LAB: {
            LABORATORY: 'Laboratorio',
            SEE_ALL_APPOINTMENTS: 'Ver todas las citas',
            INCLUDE_RESULTS: 'Incluir Resultados',
            RESULTS: 'Resultados',
            PENDING: 'Pendientes',
            DATE: 'Fecha',
            TEST: 'Prueba',
            DETAIL: 'Detalle',
            REQUESTED: 'Solicitado',
            SPECIALTY: 'Especialidad',
            ACTION: 'Acción',
            NO_RESULTS: 'Sin resultados',
            ADD_EXTERNAL_RESULTS: 'Añadir resultados externos',
            BACK: 'Atrás',
            SEARCH_FILE: 'Buscar archivo',
            ADD: 'Adjuntar',
            LOADING: 'Cargando . . .',
            SAVED: 'Archivo subido exitosamente',
            FILE_ERROR_TYPE: 'El archivo debe ser de tipo PDF',
            LOADED: 'Archivo cargado',
            ERROR_LOADING: 'Error cargando el archivo',
            EXAM_DETAILS: 'Detalles del Examen',
            EXTERNAL_LAB: 'Laboratorio Externo',
            EXAMS: 'Exámenes',
        },
        IN: {
            TITLE: 'Historia de Ingreso',
            HISTORY: 'Historial',
            CONSULT_MOTIVE: 'Motivo de Consulta',
            APPOINTMENT_MOTIVE: 'Motivo de cita',
            ACTUAL_DISEASE: 'Enfermedad Actual',
            EVOLUTION: 'Evolución',
            DIAGNOSIS: 'Diagnóstico',
            INGRESS: 'Dx - Ingreso',
            EGRESS: 'Dx - Egreso',
            PHYSICAL_TEST: 'Examen físico',
            LABORATORY: 'Laboratorio',
            IMAGES: 'Imágenes',
            PLAN: 'Plan',
            RECIPE: 'Recipe',
            RECIPES: 'Recipes',
            RECIPE_TEMPLATE: 'Plantilla de Recipe',
            PRINT_RECIPE: 'Imprimir recipe',
            DOWNLOAD_RECIPE: 'Descargar recipe',
            CLINICAL_HISTORY: 'Historia Clínica',
            ANNEX: 'Anexos',
            FILE_NOT_SUPPORTED: 'Extensión de archivos no soportada',
            MOTIVES: 'Motivos',
            NO_MOTIVE: 'Sin motivo de cita',
            DISEASE: 'Descripción de la enfermedad',
            DIAGNOSTICS: 'Diagnósticos',
            PLAN_DESCRIPTION: 'Descripción del Plan',
            CONCENTRATION: 'Concentración',
            UNIT: 'Medida',
            VIA: 'Vía',
            INTERVAL: 'Intervalo',
            OBSERVATIONS: 'Observaciones',
            SEVERITY: 'Severidad',
            MILD: 'Leve',
            NORMAL: 'Normal',
            SEVERE: 'Agudo',
            ABDOMEN: 'Abdomen',
            HEAD_NECK: 'Cabeza - Cuello',
            CARDIO_PULMONARY: 'Cardio Pulmonar',
            VERTEBRAL_COLUMN: 'Columna Vertebral',
            RIGHT_END: 'Extremidad Superior Derecha',
            INFERIOR_RIGHT_END: 'Extremidad Inferior Derecha',
            INFERIOR_LEFT_END: 'Extremidad Inferior Izquierda',
            LEFT_END: 'Extremidad Superior Izquierda',
            GENITAL: 'Genitales',
            NEUROLOGICAL: 'Neurológico',
            ORL: 'ORL',
            SKIN: 'Piel - Faneras',
            GENERAL_STATE: 'Estado General',
            REGION: 'Región',
            SAVE: 'Guardar',
            CONFIRM: 'Confirmar',
            NO_SEVERITY: 'Se debe especificar la severidad',
            DELETE: 'Eliminar',
            EDIT: 'Editar',
            TOO_LONG: 'El contenido es demasiado largo',
            INCOMPLETE_FIELDS: 'Los campos están incompletos',
            RECIPE_INCOMPLETE_FIELDS:
                'Algunos campos están incompletos, para continuar revise los medicamentos',
            RE_EVALUATION: 'Re-evaluación',
            OBSERVATION: 'Observación',
            END_APPOINTMENT: 'Finalizar Consulta',
            DIAGNOSTIC: 'Diagnóstico',
            PRINT: 'Imprimir',
            CONFIRM_END_APPOINTMENT:
                '¿Está seguro que desea finalizar la cita?',
            REQUIRED: 'Esta sección es requerida para finalizar la consulta',
            CANCEL: 'Cancelar',
            REQUIRED_FIELDS_INCOMPLETE:
                'La información requerida para finalizar la consulta está incompleta, revisar los campos marcados con "*"',
            UPDATE_PRICE_REQUIRED:
                'Complete el proceso de actualización de precio',
            SELECT_MOTIVE: 'Seleccione motivo de consulta',
            SELECT_DIAGNOSTIC: 'Seleccione diagnóstico',
            SELECT_DXI: 'Seleccione diagnóstico de ingreso',
            SELECT_DXE: 'Seleccione diagnostico de egreso',
            AREA: 'Área',
            FLOOR: 'Piso',
            ROOM: 'Cuarto',
            BED: 'Cama',
            SELECT_AREA: 'Seleccionar Área',
            SELECT_FLOOR: 'Seleccionar Piso',
            SELECT_ROOM: 'Seleccionar Cuarto',
            SELECT_BED: 'Seleccionar Cama',
            SEND_TO_OBSERVATION: 'Enviar paciente a observación',
            DETAILS: 'Detalles',
            PENDINGS: 'Pendientes',
            RESULTS: 'Resultados',
            NO_PENDING_EXAMS: 'No se encontraron exámenes pendientes',
            NO_RESULT_EXAMS: 'No se encontraron resultados',
            ADD_TEST: 'Solicitar examen',
            HOSPITAL_RELEASE: 'Dar de Alta',
            NO_OBSERVATIONS:
                'Se deben especificar las observaciones del examen físico',
            DISEASE_PLACEHOLDER:
                'Ingrese la descripción de la enfermedad actual',
            EVO_PLACEHOLDER:
                'Ingrese la descripción de la evolución del paciente',
            TEST_PLACEHOLDER: 'Describa el resultado del examen físico',
            PLAN_PLACEHOLDER: 'Registre el plan a seguir',
            RECIPE_PLACEHOLDER: 'Seleccione el medicamento a asignar',
            REGISTERING_TEST: 'Registrando examen...',
            DELETING: 'Eliminando examen...',
            INDICATIONS: 'Indicaciones',
            DOSIS: 'Dosis',
            ADD_MOTIVE: 'Agregar motivo',
            ADD_DIAGNOSTIC: 'Agregar diagnóstico',
            TORAX: 'Torax',
            EXTREMITIES: 'Extremidades',
            GENERAL: 'General',
            ABDOMINAL: 'Abdominal',
            TEST_RESULTS: 'Resultado de examen físico',
            REPORT_HISTORY: 'Informes',
            NEW: '¿Deseas asignar una nueva cita?',
            MEDICINES: 'Medicamentos',
            HISTORIC: 'Histórico de ingreso',
            ACTIVE_CALL: 'Hay una video llamada en curso ¿Desea terminarla?',
            PATIENT_NONE:
                'El paciente no está conectado ¿Desea enviar una invitación vía correo?',
            ALERT: 'Advertencia',
            COMPLETE_REQUIRED: 'Debe completar las secciones requeridas',
            ATTENTION_TIME: 'Tiempo de atención: ',
            PRINT_ORDER: 'Imprimir orden',
            DOWNLOAD_ORDER: 'Descargar orden',
            DELETE_MESSAGE: '¿Está seguro que desea eliminar?',
            MEDIC_REPORT: 'Informes médicos',
            DOSE: 'Dósis',
            MAIL_CONFIRMED: 'Correo confirmado',
            MAIL_NOT_CONFIRMED: 'Correo no confirmado',
            CONSULT_PRICE: 'Precio de la consulta:',
            EVO_NOTE: 'Nota evolutiva',
            CONFIRM_INVOICE: 'Confirmar y Facturar',
            LOADING: 'Cargando, por favor espere',
            RECIPE_TEMPLATE_ADDMSG:
                '¿Desea aplicar los medicamentos de la plantilla?',
            // RECIPE_TEMPLATE_ADDMSG:
            //     'Para añadir los medicamentos de la plantilla a los que ya se encuentran en el recipe seleccione <b>Confirmar</b> <br><br> Si selecciona <b>Reemplazar</b> los medicamentos actuales seran reemplazados en su totalidad por los que se encuentran en la plantilla',
            SELECT_TREATMENT: 'Seleccione al menos un tratamiento',
            TEMPLATE_NAME: 'Nombre de la plantilla',
            FILTER_TREATMENT: 'Buscar tratamiento',
            ITEMS_SELECTED: 'Elementos seleccionados',
            MODIFIED_BY: 'Modificado por',
            ONLY_ME_TOOLTIP:
                'Esta plantilla solo estará disponible para el médico que la crea',
            SHARE_WITH_CLINC_TOOLTIP:
                'Esta plantilla será usada por todos los médicos de la clinica',
            DELETE_RECIPE_MSG:
                '¿Está seguro que desea eliminar los tratamientos seleccionados?',
            CONFIRM_DOSE_MSG1:
                '¿Desea confirmar las dosis de los tratamientos?',
            CONFIRM_DOSE_MSG2: 'Confirmar dosis para activar esta opción',
        },
        KARDEX: {
            TITLE: 'Kardex',
            TREATMENT: 'Tratamiento',
            INTERVAL: 'Intervalo',
            OBSERVATIONS: 'Observaciones',
            SCHEDULE: 'Horario',
            REGISTERED_BY: 'Registrado por:',
            TIME_REGISTERED: 'Fecha de registro:',
            NO_TREATMENT: 'No hay tratamiento registrado',
        },
        SURGICAL_REPORT: {
            TITLE: 'Nota Operatoria',
            REQUIRED: 'Completar los campos requeridos',
            MAIN: {
                DATA: 'Datos de intervención',
                PARTICIPANTS: 'Participantes',
                DIAGNOSIS: 'Diagnostico',
                PROCEDURE: '	Descripción del procedimiento',
                REPORT: 'Informe quirúrgico',
                EDIT: 'Editar',
                FINALIZE: '¿Estás seguro que deseas finalizar el reporte?',
                END: 'Finalizar reporte',
            },
            DATA: {
                TIME: 'Tiempo de intervención',
                PRIORITY: 'Prioridad',
                ELECTIVE: 'Electiva',
                EMERGENCY: 'Emergencia',
                TRANSIT: 'Transito',
                AMBULATORY: 'Ambulatorio',
                HOSP: 'Hospitalización',
                START: 'Inicio de la cirugia',
                END: 'Termino de la cirugía',
                INTERVENTION: 'Intervenciones',
                ADDITIONAL: 'Intervenciones adicionales',
            },
            PARTICIPANTS: {
                DESCRIP: 'Descripción',
                TYPE: 'Tipo',
                MEDIC: 'Medico',
                NURSE: 'Enfermero',
                ESPECIALTY: 'Especialidad',
                DEFAULT: '¿Por defecto?',
            },
            DIAGNOSIS: {
                PRE: 'Pre-operatorios',
                POST: 'Post-operatorios',
            },
            PROCEDURE: {
                TYPE: 'Tipo de anestesia',
                FINDING: 'Hallazgos',
                EVOLUTION:
                    'Evolución y condición del paciente al termino de la cirugia',
            },
        },
        APPOINTMENT: {
            TITLE: 'Historial de Citas',
            DATE: 'Fecha',
            APPOINTMENT_TYPE: 'Tipo de Consulta',
            SPECIALTY: 'Especialidad',
            MEDIC: 'Médico',
            DIAGNOSTIC: 'Diagnóstico',
            ACTION: 'Acción',
            DETAILS: 'Ver detalles',
        },
        ALDRETE: {
            TITLE: 'Aldrete',
            ACTIVITY: 'Actividad',
            CONSCIENCE: 'Conciencia',
            COLOR: 'Color',
            CIRCULATION: 'Circulación',
            RESPIRATORY: 'Respiratorio',
            CLEAR_FORM: 'Limpiar formulario',
            SAVE: 'Guardar',
            ALDRETE_DETAILS: 'Detalles de Aldrete',
            DATE: 'Fecha/Hora',
            MEDIC: 'Médico',
            POINTS: 'Puntuación',
            NOTE: 'Nota',
            SUGGESTED_POINTS: 'Se sugiere una puntuación de',
            ACCUMULATED: 'Acumulado',
            BREATHING: 'Respiración',
            INCOMPLETE_ALDRETES: 'Los aldretes están incompletos',
        },
        CONSULT_TYPE: {
            E: 'E',
            HOSP: 'H',
            SURG: 'C',
            EC: 'CE',
            IC: 'IC',
        },
        UTIL: {
            GO_TO: 'Ir a',
            SEE: 'Ver',
        },
        BTN: {
            CLOSE: 'Cerrar',
            ACCEPT: 'Aceptar',
            CANCEL: 'Cancelar',
            SEND: 'Enviar',
            DOWNLOAD: 'Descarga',
            VIEW_FILE: 'Ver archivo',
            VIEW_IMAGE: 'Ver imagen',
            INVITE: 'Invitar Portal',
            CONFIRM_MAIL: 'Confirmar correo',
            COPY_REGISTER: 'Copiar registro',
            UPDATE: 'Actualizar',
            SEND_MAIL: 'Enviar correo',
            FULLSCREEN: 'Pantalla completa',
            QUESTIONNARIE_PRECONSULT: 'Cuestionario Pre cita',
            MONTHS: 'Meses',
            TEMPLATE: 'Plantilla',
            NEW_TREATMENT: 'Nuevo tratamiento',
            APPLY_TEMPLATE: 'Aplicar plantilla',
            PREVIEW_RECIPE: 'Ver recipe',
            SHOW_ALL: 'Mostrar todas',
            PRIVATE: 'Privada',
            ADD: 'Agregar',
            BACK: 'Regresar',
            BACK_TO_TEMPLATE: 'Regresar a la plantilla',
            NEW_TEMPLATE: 'Nueva plantilla',
            ONLY_ME: 'Solo para mi',
            SHARE_WITH_CLINC: 'Compartir con clínica',
            CANCEL_MSG: '',
            REPLACE_MSG:
                'Reemplazar los medicamentos del recipe por los medicamentos seleccionados de la plantilla',
            CONFIRM_MSG:
                'Aplicar los medicamentos seleccionados de la plantilla ',
            CHANGE_DOSE: 'Cambiar dosis',
            VIEW_NUMERIC_KEYBOARD: 'Ver teclado numerico',
            VIEW_CALCULATOR: 'Ver calculadora',
            CONFIRM_DOSE: 'Confirmar dosis',
            COPY_RESULTS: 'Copiar resultados',
            CLEAR_FORM: 'Limpiar formulario',
        },
        ERROR: {
            REQUIRED: 'Campo requerido',
            PASSWORD_MATCH: 'La contraseña no coincide',
        },
    },
};
