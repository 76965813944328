import {
    Component,
    OnInit,
    ViewChild,
    Inject,
    ViewEncapsulation,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HistoryService } from '../../history.service';
import {
    MatTableDataSource,
    MatSort,
    MatPaginator,
    MAT_DIALOG_DATA,
    MatDialog,
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
    MatDialogRef,
} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TrasladoModalComponent } from './traslado-modal/traslado-modal.component';
import { MedicoModalComponent } from './medico-modal/medico-modal.component';
import { Subject } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config.service';
import { NetworkService } from 'app/services/network-service.service';
declare var $: any;

@Component({
    selector: 'app-datos_paciente',
    templateUrl: './datos_paciente.component.html',
    styleUrls: ['./datos_paciente.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DatosPacienteComponent implements OnInit, OnChanges {
    private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    private verticalPosition: MatSnackBarVerticalPosition = 'top';

    sangre = [];
    data: any;
    traslado: any;
    view: any;
    isNurse: boolean = false
    showSidebar: boolean

    subject = new Subject<any>();

    @Input() consulta_id: string;
    @Input() paciente_id: string;
    @Input() cita_id: string;

    @Output() ingresado = new EventEmitter();

    constructor(
        private route: ActivatedRoute,
        private historyService: HistoryService,
        private fuseProgressBarService: FuseProgressBarService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        private dom: DomSanitizer,
        private fuseConfig: FuseConfigService,
        private networkService: NetworkService
    ) {
        this.data = {
            paciente: {
                id: '0',
                nombre: 'Cargando...',
                apellido: 'Cargando...',
                identificacion: 'Cargando...',
                sexo: '',
                edad: '',
                foto: 'assets/images/avatars/profile.jpg',
                historia: 'Cargando...',
                responsable: 'Cargando...',
                especialidad: 'Cargando...',
                diagnostico: 'Cargando...',
                ubicacion: 'Cargando...',
                ingresado: false,
                sangre: {
                    id: '0',
                    descripcion: 'SGS',
                },
            },
            notas: [],
            notas_length: 0,
        };
    }

    ngOnInit(): void {
        this.networkService.getShowSidebar().subscribe(response => this.showSidebar = response)
        this.fuseConfig.getConfig().subscribe(data => this.isNurse = data.isNurse)
        this.view = this.route.snapshot.paramMap.get('view_id');
        this.obtenerPaciente(this.paciente_id, this.cita_id);
    }

    // Cuando hay un cambio en url lo detecta
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.cita_id.currentValue !== changes.cita_id.previousValue) {
            this.obtenerPaciente(this.paciente_id, this.cita_id);
        }
    }

    obtenerPacienteOtros(cita_id): void {
        this.historyService.getPacienteDatosOtros(cita_id).subscribe(
            (res) => {
                this.data.paciente.responsable = res.responsable;
                this.data.paciente.especialidad = res.especialidad;
                this.data.paciente.diagnostico = res.diagnostico;
                this.data.paciente.ubicacion = res.ubicacion;
                this.data.paciente.tratante = res.tratante;
                this.data.paciente.ingresado = res.ingresado;
                this.ingresado.emit(this.data.paciente.ingresado);
            },
            (err) => {
                // console.log(err);
            }
        );
    }

    obtenerPaciente(paciente_id, cita_id): void {
        this.historyService.getPatientId(paciente_id).subscribe(
            (res) => {
                const edad = res.edad;

                var anos = '';
                var meses = '';
                var dias = '';
                var resultado_edad = '';

                // Primero verificamos los meses y dias
                if (edad.meses > 1) {
                    var meses = edad.meses + ' meses';
                } else if (edad.meses === 0) {
                    var meses = '';
                } else {
                    var meses = edad.meses + ' mes';
                }

                if (edad.dias > 1) {
                    var dias = edad.dias + ' días';
                } else if (edad.dias === 0) {
                    var dias = '';
                } else {
                    var dias = edad.dias + ' día';
                }

                // Despues los años
                if (edad.anos > 3) {
                    var anos = edad.anos + ' años';
                    resultado_edad = anos;
                } else if (edad.anos === 0) {
                    var anos = '';
                    resultado_edad = anos + ' ' + meses + ' ' + dias;
                } else {
                    if (edad.anos === 1) {
                        var anos = edad.anos + ' año';
                    } else {
                        var anos = edad.anos + ' años';
                    }
                    resultado_edad = anos + ' ' + meses + ' ' + dias;
                }

                this.data.paciente = {
                    id: res.id,
                    nombre: res.nombre,
                    apellido: res.apellido,
                    identificacion: res.identificacion,
                    sexo:
                        res.sexo === 'M'
                            ? 'Masculino'
                            : res.sexo === 'F'
                            ? 'Femenino'
                            : '???',
                    edad: resultado_edad,
                    foto: this.dom.bypassSecurityTrustUrl(res.foto),
                    historia: res.historia,
                    telefono: res.telefono,
                    direccion: res.direccion,
                    sangre: {
                        id: '',
                        descripcion: 'SGS',
                    },
                };
                this.obtenerGrupoSanguineo(paciente_id);
                this.getListaSanguineo();
                this.obtenerNotas(cita_id);
                this.obtenerPacienteOtros(cita_id);
                this.getTraslado(cita_id);
            },
            (err) => {
            }
        );
    }

    obtenerNotas(cita_id): void {
        // this.data.notas = [];
        this.historyService.getNotas(cita_id).subscribe(
            (res) => {
                this.data.notas_length = res.length;
                res.forEach((element) => {
                });
            },
            (err) => {
            }
        );
    }

    obtenerGrupoSanguineo(paciente_id): void {
        this.historyService.getGrupoSanguineo(paciente_id).subscribe(
            (res) => {
                this.data.paciente.sangre = {
                    id: res.id,
                    descripcion: res.descripcion ? res.descripcion : 'SGS',
                };
            },
            (err) => {
                console.log(err);
            }
        );
    }

    getListaSanguineo(): void {
        this.historyService.getListaSanguineo().subscribe(
            (res) => {
                this.sangre = [...res];
            },
            (err) => {
                console.log(err);
            }
        );
    }

    openModal(data, tipo): void {
        const modalContent = this.dialog.open(NotasModal, {
            data: {
                content: data,
                title: tipo,
                cita_id: this.route.snapshot.paramMap.get('cita_id'),
            },
            width: '1000px',
            height: 'auto',
        });

        modalContent.afterClosed().subscribe((result) => {
        });
    }

    trasladoModal(): void {
        const modal = this.dialog
            .open(TrasladoModalComponent, {
                width: '600px',
                height: '370px',
                panelClass: 'custom-dialog',
                data: {
                    paciente_id: this.route.snapshot.paramMap.get(
                        'paciente_id'
                    ),
                    cita_id: this.route.snapshot.paramMap.get('cita_id'),
                    consulta_id: parseInt(
                        this.route.snapshot.paramMap.get('consulta_id')
                    ),
                    medico: this.traslado.medico[0].medico,
                    especialidad: this.traslado.medico[0].especialidad,
                },
            })
            .afterClosed()
            .subscribe((response) => {
                if (response !== undefined) {
                    if (response.status === 200) {
                        const paciente_id = this.route.snapshot.paramMap.get(
                            'paciente_id'
                        );
                        const cita_id = this.route.snapshot.paramMap.get(
                            'cita_id'
                        );

                        this.obtenerPaciente(paciente_id, cita_id);
                    }
                }
                modal.unsubscribe();
            });
    }

    medicoModal(): void {
        const consulta_id = parseInt(
            this.route.snapshot.paramMap.get('consulta_id')
        );

        if (consulta_id !== 2) {
            const modal = this.dialog
                .open(MedicoModalComponent, {
                    width: '600px',
                    height: '370px',
                    // position: {
                    //     left: `64px`,
                    //     top: `270px`,
                    // },
                    panelClass: 'custom-dialog',
                    data: {
                        paciente_id: this.route.snapshot.paramMap.get(
                            'paciente_id'
                        ),
                        cita_id: this.route.snapshot.paramMap.get('cita_id'),
                        consulta_id: consulta_id,
                        medico: this.traslado.medico[0].medico,
                        especialidad: this.traslado.medico[0].especialidad,
                        cama: this.traslado.ubicacion[0].cama,
                    },
                })
                .afterClosed()
                .subscribe((response) => {
                    if (response !== undefined) {
                        if (response.status === 200) {
                            const paciente_id = this.route.snapshot.paramMap.get(
                                'paciente_id'
                            );
                            const cita_id = this.route.snapshot.paramMap.get(
                                'cita_id'
                            );

                            this.obtenerPaciente(paciente_id, cita_id);
                        }
                    }
                    modal.unsubscribe();
                });
        }
    }

    registarSangre(id_sangre): void {
        const paciente_id = this.route.snapshot.paramMap.get('paciente_id');

        const body = {
            grupo_sanguineo: id_sangre,
        };

        this.historyService.postGrupoSanguineo(paciente_id, body).subscribe(
            (res) => {
                this.snackBar.open(res.message, 'OK', {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    duration: 3000,
                    panelClass: 'custom-blue',
                });
                this.obtenerGrupoSanguineo(paciente_id);
                this.fuseProgressBarService.hide();
            },
            (err) => {
                this.snackBar.open(err.message, 'ERROR', {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    duration: 3000,
                    panelClass: 'custom-red',
                });
                this.fuseProgressBarService.hide();
            }
        );
    }

    getTraslado(cita_id): void {
        this.fuseProgressBarService.show();
        this.historyService.getTraslado(cita_id).subscribe(
            (res) => {
                this.traslado = { ...res };
                this.fuseProgressBarService.hide();
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this.snackBar.open(err.error.message, 'ERROR', {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    duration: 3000,
                    panelClass: 'custom-red',
                });
            }
        );
    }

    /**
     * Funcion de estilos
     */
    bgColor(id: string): any {
        return id === this.data.paciente.sangre.id
            ? {
                  backgroundColor: '#eeeeee',
              }
            : '';
    }
}

@Component({
    selector: 'notas-modal',
    templateUrl: 'notas-modal.html',
})
export class NotasModal implements OnInit {
    content: any;
    traduccion: any;
    form: FormGroup;
    summernote: any;

    public displayedColumns: string[] = ['datos'];
    public columnsToDisplay: string[] = this.displayedColumns.slice();
    public dataSource: MatTableDataSource<any>;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    public verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<NotasModal>,
        private _fuseProgressBarService: FuseProgressBarService,
        private snackBar: MatSnackBar,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private historyService: HistoryService,
        private _formBuilder: FormBuilder
    ) {
        this.content = data;
        this.summernote = $(document.getElementById('summernote'));
        this.dataSource = new MatTableDataSource(this.data.notas);
    }

    ngOnInit(): void {
        // this.dataSource.paginator = this.paginator;
        this.form = this._formBuilder.group({});
        this.dataSource.sort = this.sort;
        this.obtenerNotas(this.data.cita_id);
        this.initSummernote();
    }

    initSummernote(): void {
        const summernote = $(document.getElementsByClassName('summernote'));
        summernote.summernote({
            placeholder: '',
            lang: 'es-ES',
            tabsize: 1,
            width: '100%',
            height: 'auto', // set editor height
            minHeight: 100, // set minimum height of editor
            maxHeight: null, // set maximum height of editor
            focus: true, // set focus to editable area after initializing summernote,
            toolbar: [
                // [groupName, [list of button]]
                [
                    'style',
                    [
                        'style',
                        'fontsize',
                        'bold' /*'italic'*/,
                        ,
                        'underline',
                        'clear',
                        'fontname',
                        'strikethrough',
                    ],
                ],
                ['font', ['color']],
                ['para', ['ul', 'ol', 'paragraph', 'table', 'height']],
                ['insert', ['link', 'picture', 'video']],
                ['misc', ['undo', 'redo', 'codeview', 'help']],
            ],
        });

        summernote.summernote('code', '');
        // var note_toolbar = document.getElementsByClassName("note-toolbar");
        // note_toolbar[0].setAttribute("style", "zoom: 85%;")
    }

    cerrar(): void {
        this.dialogRef.close();
    }

    obtenerNotas(cita_id): void {
        this.data.notas = [];
        this._fuseProgressBarService.show();
        this.historyService.getNotas(cita_id).subscribe(
            (res) => {
                this._fuseProgressBarService.hide();
                res.forEach((element) => {
                    this.data.notas.push({
                        id: element.id,
                        nota: element.nota,
                        usuario_reg: element.usuario_reg,
                        usuario_nombre: element.usuario_nombre,
                        usuario_apellido: element.usuario_apellido,
                        usuario_nombre_completo:
                            element.usuario_nombre_completo,
                        valor:
                            element.nota +
                            '<br>' +
                            element.fecha_reg +
                            ' / ' +
                            '<span style="font-weight: bold">' +
                            element.usuario_nombre_completo +
                            '</span>',
                        // valor: element.fecha_reg + ' - ' + element.nota + ' - ' + element.usuario_nombre_completo,
                        fecha_reg: element.fecha_reg,
                    });
                });
                this.dataSource.data = this.data.notas;
            },
            (err) => {
                this._fuseProgressBarService.hide();
                // console.log(err);
            }
        );
    }

    guardarNotas(): void {
        if (this.form.valid) {
            this.snackBar.open('Cargando. . .', 'OK', {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 99999,
                panelClass: 'custom-blue',
            });
            const summernote = $(document.getElementsByClassName('summernote'));
            const body = {
                cita: this.data.cita_id,
                nota: summernote.summernote('code'),
            };

            this.historyService.postNotas(body).subscribe(
                (res) => {
                    this.snackBar.open(res.message, 'OK', {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 1500,
                        panelClass: 'custom-green',
                    });

                    this.form.reset();
                    summernote.summernote('code', '');
                    this.obtenerNotas(this.data.cita_id);
                },
                (err) => {
                    this.snackBar.open(err.error.message, 'ERROR', {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 3000,
                        panelClass: 'custom-red',
                    });
                }
            );
        }
    }
}
