import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NetworkService } from 'app/services/network-service.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public isAuthenticated = false;
    constructor(private networkService: NetworkService) {}

    login(user): Observable<any> {
        const body = {
            email: user.email,
            password: user.password,
        };
        return this.networkService.post('users/authenticate', body);
    }

    logout(): Observable<any> {
        return this.networkService.get('users/logout');
    }

    getProfile(): Observable<any> {
        return this.networkService.get('user/');
    }

    getLogo(): Observable<any> {
        return this.networkService.get('logo');
    }

    /**
     * Login de pacientes - portal
     * @param body { "email": "string", "password": "string" }
     */
    login_patient(body: JSON): Observable<any> {
        return this.networkService.post(`paciente/authenticate`, body);
    }

    /**
     * Registro de pacientes para uso del portal
     * @param body { "nombre": "string", "apellido": "string", "nacimiento": "2020-07-29", "sexo": "M", "correo": "string", "password": "string" }
     */
    register(body: JSON): Observable<any> {
        return this.networkService.post(`users/register/paciente`, body);
    }

    /**
     * Confirmación de registro
     * @param param parametro que retorna en la respuesta de registro de paciente
     */
    confirmRegister(param1: string): Observable<any> {
        return this.networkService.post(`users/confirm?param1=${param1}`);
    }
}
