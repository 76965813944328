import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    MatExpansionModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDividerModule,
    MatTabsModule,
    MatTreeModule,
} from '@angular/material';
import { MatMenuModule } from '@angular/material/menu';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { IMaskModule } from 'angular-imask';
import {
    DatosPacienteComponent,
    NotasModal,
} from './datos_paciente/datos_paciente.component';
import { AlergiasComponent } from './alergias/alergias.component';
import { SignosVitalesComponent } from './signos_vitales/signos_vitales.component';
import { MenuIzquierdoComponent } from './menu_izquierdo.component';
import { HistorialCitasComponent } from './historial_citas/historial_citas.component';
import { AntecedentesComponent } from './antecedentes/antecedentes.component';
import { BotoneraAccionesMenu } from './botonera_acciones_menu/botonera_acciones_menu.component';
import { RouterModule } from '@angular/router';
import { PipesCustomModule } from 'app/pipes/pipes.module';
import { TreeModule } from 'angular-tree-component';
import { SignosDialogComponent } from './signos_vitales/signos-dialog/signos-dialog.component';
import { SignosChartComponent } from './signos_vitales/signos-chart/signos-chart.component';

@NgModule({
    declarations: [
        MenuIzquierdoComponent,
        DatosPacienteComponent,
        AlergiasComponent,
        SignosVitalesComponent,
        HistorialCitasComponent,
        AntecedentesComponent,
        BotoneraAccionesMenu,
        SignosDialogComponent,
        SignosChartComponent,
        NotasModal,
    ],
    imports: [
        RouterModule,
        TranslateModule,

        // Fuse
        FuseSharedModule,

        // Material
        MatExpansionModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatDividerModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatMenuModule,
        MatTabsModule,
        MatTreeModule,

        // Others
        NgxChartsModule,
        ChartsModule,
        TreeModule.forRoot(),
        IMaskModule,

        // Pipes Custom Module
        PipesCustomModule,
    ],

    exports: [
        MenuIzquierdoComponent,
        DatosPacienteComponent,
        AlergiasComponent,
        SignosVitalesComponent,
        HistorialCitasComponent,
        AntecedentesComponent,
        BotoneraAccionesMenu,
        SignosChartComponent,
    ],
    entryComponents: [NotasModal, SignosDialogComponent],
})
export class MenuIzquierdoModule {}
