export const locale = {
    lang: 'en',
    data: {
        'LOGOUT': {
            'TITLE': 'Logout',
            'MESSAGE': '¿Are you sure you want to logout?',
            'YES': 'Yes',
            'NO': 'No'
        }
    }
};
