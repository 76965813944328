import { Component, OnInit, Inject } from '@angular/core';
import {
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
    MatSnackBar,
    MAT_DIALOG_DATA,
} from '@angular/material';
import { locale as english } from '../../../medic/history/i18n/en';
import { locale as spanish } from '../../../medic/history/i18n/es';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { HistoryService } from 'app/main/medic/history/history.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CitasService } from 'app/main/medic/history/citas/citas.service';
import { PortalService } from '../../portal.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-citas-modal',
    templateUrl: './citas-modal.component.html',
    styleUrls: ['./citas-modal.component.scss'],
})
export class CitasModalComponent implements OnInit {
    private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    private verticalPosition: MatSnackBarVerticalPosition = 'top';

    citas = [];
    view = '';
    position = 0;
    info = {
        foto: '',
        edad: {},
    };
    consulta_id: any;
    cita_id: any;
    paciente_id: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fuseTranslate: FuseTranslationLoaderService,
        private snackBar: MatSnackBar,
        private dom: DomSanitizer,
        private progressBar: FuseProgressBarService,
        private portalService: PortalService,
        private historyService: HistoryService,
        private citasServ: CitasService
    ) {
        this.fuseTranslate.loadTranslations(english, spanish);
    }

    ngOnInit(): void {
        this.consulta_id = this.data.consulta_id;
        this.cita_id = this.data.cita_id;
        this.paciente_id = this.data.paciente_id;

        this.obtenerHistoricoCitas(this.paciente_id);
        this.getPacienteById(this.paciente_id);
        this.citasServ.option.subscribe((string) => {
            this.view = string;
        });
    }

    obtenerHistoricoCitas(paciente_id): void {
        // this.progressBar.show();
        this.historyService.getHistoricoCitas(paciente_id).subscribe(
            (res) => {
                // this.progressBar.hide();
                this.citas = res;
                this.arrangeCita(this.citas, this.cita_id);
                switch (this.consulta_id) {
                    case '99':
                        this.view = 'listar_ordenes_medicas';
                        break;

                    case '6':
                        this.view = 'listar_ordenes_medicas';
                        break;

                    case '2':
                        this.view = 'notas_operatorias';
                        break;

                    case '1':
                        this.view = 'ingreso';
                        break;
                }
            },
            (err) => {
                // this.progressBar.hide();
                this.snackBar.open(err.error.message, 'ERROR', {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    duration: 3000,
                    panelClass: 'custom-red',
                });
            }
        );
    }

    /**
     * Coloca la cita seleccionada de primera en la lista
     * @param arr array con la informacion del historial de cita
     */
    arrangeCita(arr: any, om_id): void {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === om_id) {
                const ele = arr[i];
                ele.year = ele.fecha.substring(0, 10);
                ele.time = ele.fecha.substring(11, 25);
                arr.splice(i, 1);
                arr.unshift(ele);
                break;
            }
        }
    }

    changeCita(type: string): void {
        switch (type) {
            case 'first':
                this.position = 0;
                break;

            case 'back':
                if (this.position > 0) {
                    this.position--;
                }
                break;

            case 'next':
                if (this.position < this.citas.length - 1) {
                    this.position++;
                }
                break;

            case 'last':
                this.position = this.citas.length - 1;
                break;
        }

        this.consulta_id = this.citas[this.position].tipo_consulta.id;
        this.cita_id = this.citas[this.position].id;

        this.view = '';
        setTimeout(() => {
            switch (this.consulta_id) {
                case '99':
                    this.view = 'listar_ordenes_medicas';
                    break;

                case '6':
                    this.view = 'listar_ordenes_medicas';
                    break;

                case '2':
                    this.view = 'notas_operatorias';
                    break;

                case '1':
                    this.view = 'ingreso';
                    break;
            }
        }, 500);
    }

    getPacienteById(paciente_id): void {
        this.portalService.getPacienteById(paciente_id).subscribe(
            (res) => {
                this.info = { ...res };
                // this.info.foto = this.dom.bypassSecurityTrustUrl(this.info.foto);
            },
            (err) => {
                this.snackBar.open(err.status + err.message, 'ERROR', {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    duration: 3000,
                    panelClass: 'custom-red',
                });
            }
        );
    }

    goHistorialCitas(): void {
        window.open(
            `#/medic/history/${this.paciente_id}/${this.cita_id}/citas/${this.consulta_id}/${this.cita_id}`,
            '_blank'
        );
    }
}
