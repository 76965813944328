export const rolesNavigation = {
    medico: {
        id: 'medic_menu',
        title: 'Médico',
        translate: 'NAV.MEDIC_MENU',
        type: 'group',
        children: [
            {
                id: 'patients_of_the_day',
                title: 'Pacientes del Día',
                translate: 'NAV.PATIENTS_OF_THE_DAY.TITLE',
                type: 'item',
                icon: 'account_circle',
                url: '/medic/patients_of_the_day',
            },
            // {
            //     id: 'presupuesto',
            //     title: 'Presupuesto',
            //     translate: 'NAV.PRESUPUESTO.TITLE',
            //     type: 'item',
            //     icon: 'ballot',
            //     url: '/presupuesto',
            // },
            {
                id: 'honorarios',
                title: 'Honorarios',
                translate: 'NAV.HONORARIOS.TITLE',
                type: 'item',
                icon: 'account_balance_wallet',
                url: '/honorarios',
            },
            {
                id: 'agenda',
                title: 'Agenda',
                translate: 'NAV.AGENDA.TITLE',
                type: 'item',
                icon: 'calendar_today',
                url: '/agenda',
            },
            // {
            //     id: 'dashboard',
            //     title: 'Dashboard',
            //     translate: 'NAV.DASHBOARD.TITLE',
            //     type: 'item',
            //     icon: 'dashboard',
            //     function: () => {
            //         window.open('https://www.w3schools.com', '_blank');
            //     },
            // },
            // {
            //     id: 'portal',
            //     title: 'Portal',
            //     translate: 'NAV.AGENDA.TITLE',
            //     type: 'group',
            //     // icon: 'calendar_today',
            //     // url: '/agenda',
            //     children: [
            //         {
            //             id: 'home_portal',
            //             title: 'Portal Medico Home',
            //             translate: 'NAV.PORTAL.HOME',
            //             type: 'item',
            //             image: 'assets/icons/portal/home_color.png',
            //             url: '/portal/home',
            //         },
            //         {
            //             id: 'paciente_portal',
            //             title: 'Pacientes portal medico',
            //             translate: 'NAV.PORTAL.PATIENT',
            //             type: 'item',
            //             image: 'assets/icons/portal/lista_color.png',
            //             url: '/portal/paciente',
            //         },
            //         {
            //             id: 'calendario_medico',
            //             title: 'Calendario medico',
            //             translate: 'NAV.PORTAL.REPORT',
            //             type: 'item',
            //             image: 'assets/icons/portal/atender_paciente_color.png',
            //             url: '/portal/atender_calendario',
            //         },
            //         {
            //             id: 'Informes medicos',
            //             title: 'Pacientes del Día',
            //             translate: 'NAV.PORTAL.ATTEND',
            //             type: 'item',
            //             image: 'assets/icons/portal/informe_medico_color.png',
            //             url: '/portal/informes',
            //         },
            //         {
            //             id: 'Configuracion',
            //             title: 'configuracion ',
            //             translate: 'NAV.PORTAL.CHART',
            //             type: 'item',
            //             image: 'assets/icons/portal/reportes_color.png',
            //             url: '/portal/config',
            //         },
            //     ],
            // },
        ],
    },
    admin: {
        id: 'admin_menu',
        title: 'Administrador',
        translate: 'NAV.ADMIN_MENU',
        type: 'group',
        children: [],
    },
    enfermeria: {
        id: 'nurse_menu',
        title: 'Enfermeria',
        translate: 'NAV.NURSE_MENU',
        type: 'group',
        children: [
            {
                id: 'patients_of_the_day',
                title: 'Pacientes del Día',
                translate: 'NAV.PATIENTS_OF_THE_DAY.TITLE',
                type: 'item',
                icon: 'account_circle',
                url: '/medic/patients_of_the_day',
            },
        ],
    },
    paciente: {
        id: 'paciente_menu',
        title: 'Médico',
        translate: 'NAV.MEDIC_MENU',
        type: 'group',
        children: [
            {
                id: 'home_paciente',
                title: 'Portal Medico Home',
                translate: 'NAV.PORTAL.HOME',
                type: 'item',
                image: 'assets/icons/portal/home_color.png',
                url: '/pacientes/home',
            },
            {
                id: 'calendario_paciente',
                title: 'Calendario paciente',
                translate: 'NAV.PORTAL.ATTEND',
                type: 'item',
                image: 'assets/icons/portal/atender_paciente_color.png',
                url: '/pacientes/calendario',
            },
            {
                id: 'informe_paciente',
                title: 'Informes paciente',
                translate: 'NAV.PORTAL.REPORT',
                type: 'item',
                image: 'assets/icons/portal/informe_medico_color.png',
                url: '/pacientes/informes',
            },
        ],
    },
};
