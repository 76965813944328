export const locale = {
    lang: 'en',
    data: {
        NAV: {
            ACCOUNT: 'Account',
            PROFILE: {
                TITLE: 'Profile',
                BADGE: '25',
                PASSWORD: 'New Password',
                CONFIRM_PASSWORD: 'Confirm New Password',
            },
            HOME: {
                TITLE: 'Home',
                BADGE: '25',
            },
            MEDIC_MENU: 'Medic',
            ADMIN_MENU: 'Admin',
            PATIENTS_OF_THE_DAY: {
                TITLE: 'Patients of the Day',
            },
            PRESUPUESTO: {
                TITLE: 'Budget',
            },
            HONORARIOS: {
                TITLE: 'Fee',
            },
            AGENDA: {
                TITLE: 'Medic Agenda',
            },
            HOSPITALIZATION: {
                TITLE: 'Hospitalization',
            },
            DASHBOARD: {
                TITLE: 'Dashboard',
            },
            LOGOUT: {
                TITLE: 'Logout',
                MESSAGE: '¿Are you sure you want to logout?',
                YES: 'Yes',
                NO: 'No',
            },
            PATIENTS: {
                TITLE: 'Patients',
            },
            CALENDAR: {
                TITLE: 'Calendar',
            },
            PORTAL: {
                HOME: 'Home',
                PATIENT: 'Patients',
                ATTEND: 'Attend patients',
                REPORT: 'Medical reports',
                CHART: 'Reports',
            },
        },
    },
};
