import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@angular/material';
import {
    FormGroup,
    FormBuilder,
    Validators,
    ValidatorFn,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { HistoryService } from 'app/main/medic/history/history.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as es } from '../i18n/es';
import { locale as en } from '../i18n/en';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
    userForm: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private historyService: HistoryService,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ChangePasswordComponent>,
        private bar: FuseProgressBarService,
        private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService
    ) {
        this.translationLoader.loadTranslations(es, en);
    }

    ngOnInit(): void {
        // console.log(this.data);
        this.userForm = this.fb.group({
            // nombre: [{ value: this.data.body.nombre, disabled: true }],
            // apellido: [{ value: this.data.body.apellido, disabled: true }],
            passwordNew: [
                '',
                [
                    Validators.minLength(6),
                    Validators.required,
                    Validators.maxLength(10),
                ],
            ],
            passwordConfirm: [
                '',
                [
                    Validators.minLength(6),
                    Validators.required,
                    confirmPasswordValidator,
                    Validators.maxLength(10),
                ],
            ],
        });
    }

    /**
     * Actualizar contraseña
     */
    updateUser(): void {
        const body = this.userForm.value;
        // body.nombre = this.data.body.nombre;
        // body.apellido = this.data.body.apellido;
        // body.correo =
        //     this.data.body.correo !== undefined
        //         ? this.data.body.correo
        //         : this.data.body.login;
        // body.rol_administrativo = this.data.body.rol_administrativo;
        // body.rol_medico = this.data.body.rol_medico;
        // body.rol_secretaria = this.data.body.rol_secretaria;

        this.bar.show();
        this.historyService.updateUserConfig(this.data.id, body).subscribe(
            (res) => {
                this.bar.hide();
                this.snackBar.open(this.translate.instant(res.message), '', {
                    duration: 3000,
                    verticalPosition: 'top',
                    panelClass: 'custom-blue',
                });
                this.dialogRef.close();
            },
            (err) => {
                this.bar.hide();
                this.snackBar.open(err.message, '', {
                    duration: 3000,
                    verticalPosition: 'top',
                    panelClass: 'custom-red',
                });
            }
        );
    }
}

export const confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('passwordNew');
    const confirmPassword = control.parent.get('passwordConfirm');

    if (!password || !confirmPassword) {
        return null;
    }

    if (confirmPassword.value === '') {
        return null;
    }

    if (password.value === confirmPassword.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
