import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
    Inject,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AuthService } from 'app/main/auth/auth.service';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    languages: any;
    selectedLanguage: any;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private dialog: MatDialog
    ) {
        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us',
            },
            {
                id: 'es',
                title: 'Español',
                flag: 'es',
            },
        ];

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation =
            this.navigation ||
            this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang,
        });

        const logout = this._fuseNavigationService.getNavigationItem('logout');
        const language = this._fuseNavigationService.getNavigationItem(
            'language'
        );
        if (logout === false) {
            const customFunctionNavItem = {
                id: 'logout',
                title: 'Desconectarse',
                type: 'item',
                translate: 'NAV.LOGOUT.TITLE',
                icon: 'exit_to_app',
                function: () => {
                    this.goLogout();
                },
            };
            this._fuseNavigationService.addNavigationItem(
                customFunctionNavItem,
                'account'
            );
        }

        if (language === false) {
            const customFunctionNavItem = {
                id: 'language',
                title: 'Language: EN',
                type: 'collapsable',
                translate: 'NAV.LANGUAGE.TITLE',
                icon: 'language',
                hidden: true, // Se ocultara mientras tanto
                children: [
                    {
                        id: 'en',
                        title: 'Inglés',
                        type: 'item',
                        translate: 'NAV.LANGUAGE.ENGLISH',
                        image: 'assets/icons/flags/us.png',
                        function: () => {
                            this.setLanguage({
                                id: 'en',
                                title: 'English',
                                flag: 'us',
                            });
                        },
                    },
                    {
                        id: 'es',
                        title: 'Español',
                        type: 'item',
                        translate: 'NAV.LANGUAGE.SPANISH',
                        image: 'assets/icons/flags/es.png',
                        function: () => {
                            this.setLanguage({
                                id: 'es',
                                title: 'Spanish',
                                flag: 'es',
                            });
                        },
                    },
                ],
            };
            this._fuseNavigationService.addNavigationItem(
                customFunctionNavItem,
                'account'
            );
        }
        this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    }

    goLogout() {
        const dialog = this.dialog.open(LogoutDialogComponent, {
            data: {},
        });
    }

    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);

        // Set selected language on localStorage
        localStorage.setItem('language', lang.id);
    }
}

@Component({
    selector: 'logout-dialog',
    templateUrl: './logout-dialog/logout-dialog.component.html',
})
export class LogoutDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<any>,
        public authService: AuthService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {}

    cancel(): void {
        this.dialogRef.close();
    }

    goLogout(): void {
        this.authService.logout().subscribe(
            (data) => {
                localStorage.removeItem('authToken');
                sessionStorage.removeItem('authToken');
                this.router.navigate(['/auth']);
                this.dialogRef.close();
            },
            (err) => {
                // console.log(err);
                localStorage.removeItem('authToken');
                sessionStorage.removeItem('authToken');
                this.router.navigate(['/auth']);
                this.dialogRef.close();
            }
        );
    }
}
