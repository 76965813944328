export const locale = {
    lang: 'es',
    data: {
        'LOGOUT': {
            'TITLE': 'Desconectarse',
            'MESSAGE': '¿Estás seguro que quiere desconectarse?',
            'YES': 'Sí',
            'NO': 'No'
        }
    }
};
